<template>
  <div class="navigator-container">
    <detail-navigation-bar :tabs="this.tabs" />
    <router-view></router-view>
  </div>
</template>

<script>
import DetailNavigationBar from '@/components/views/navigation/DetailNavigationBar';

export default {
  name: 'NavigatorContainer',
  components: {
    DetailNavigationBar
  },
  computed: {
    tabs() {
      return [
        { name: 'NavigatorOverview', title: 'Overview' },
        { name: 'NavigatorDetails', title: 'Details' },
        { name: 'NavigatorSettings', title: 'Settings' }
      ];
    }
  }
};
</script>

<style scoped>
.navigator-container {
  max-width: 75em;
  margin: 0 auto calc(0.5em + 1.5vw);
}

@media all and (min-width: 30em) {
  .navigator-container {
   padding: 1.5rem;
  }

}
</style>