<template>
  <nav v-if="viewerMode != 'Settings'">
    <router-link v-for="tab in tabs" :key="tab.name" :to="{ name: tab.name }">{{ tab.title }}</router-link>
  </nav>
  <nav v-else>
    <a class="insights-settings" :class="{  'router-link-exact-active': isActive(tab.name),  'router-link-active': isActive(tab.name) }" v-for="tab in tabs" :key="tab.name" @click='settingsTabClicked(tab.name)'>{{ tab.title }}</a>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DetailNavigationBar',
  props: {
    tabs: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('project', ['viewerMode', 'settingsTabActive', 'selectedProject', 'selectedStudy'])
  },
  created() {
    this.$store.dispatch('project/setSettingsTabActive', this.$route.query.tab);
  },
  methods: {
    isActive(tabName) {
      return tabName == this.settingsTabActive;
    },
    settingsTabClicked(tabName) {
      this.$store.dispatch('project/setSettingsTabActive', tabName);
      if (this.selectedStudy) {
        this.$router.push({ 
          name: 'ViewerContainer',
          params: {
            id: this.selectedProject.id,
          },
          query: {
            tab: tabName
          }
        });
      } else {
        this.$router.push({ 
          name: 'ViewerContainerNoScenarios',
          params: {
            id: this.selectedProject.id,
            tabName: 'properties'
          },
          query: {
            tab: tabName
          }
        });
      }
      
    }
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'navigator') {
        this.$store.dispatch('project/setSettingsTabActive', to.query.tab);
      } else if (to.query.tab == 'details') {
        this.$store.dispatch('project/setSettingsTabActive', to.query.tab);
      } else if (to.query.tab == 'scenarios') {
        this.$store.dispatch('project/setSettingsTabActive', to.query.tab);
      } else if (to.query.tab == 'share-settings') {
        this.$store.dispatch('project/setSettingsTabActive', to.query.tab);
      } else if (to.query.tab == 'admin') {
        this.$store.dispatch('project/setSettingsTabActive', to.query.tab);
      } else {
        this.$store.dispatch('project/setSettingsTabActive', to.query.tab);
      }
    }
  }
};
</script>

<style scoped>
nav {
  margin: 0 auto;
  padding-top: 0;
  border-bottom: 0.25rem solid var(--grey-400);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

a {
  display: inline-block;
  color: var(--grey-700);
  padding: 0.75em calc(1em + 1vw) 0.5em;
  text-decoration: none;
  font-size: 0.9em;
  margin: 0;
  font-weight: 600;
  transition: color 200ms ease-in-out;
}

a:hover {
  color: var(--grey-900);
}

.insights-settings:hover {
  cursor: pointer;
}

.router-link-active {
  color: var(--grey-900);
  margin-bottom: -0.25rem;
  border-bottom: 0.25rem solid var(--primary-cerulean);
}
</style>